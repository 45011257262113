export const SIDEBAR_GUIDES = [
    {id: 1, text: 'Getting Started Guide'},
    {id: 2, text: 'Buying, Selling, Offers, and Transferring NFTs'},
    {id: 3, text: '$SHIB Burns, Platform Fees, and Creator Fees'},
    {id: 4, text: 'Creating Collections and NFTs', link: 'https://x.com/PawzaarOfficial/status/1715410840512291066'},
    {id: 5, text: 'Discovering NFT Collections'},
    {id: 6, text: 'How to Get Your Collection Verified'},
    {id: 7, text: 'Pawzaar Launchpad, Intake Form, and Marketing'},
]









